import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
import App from "./App.vue";

Vue.config.productionTip = false;

import home from "./views/home.vue";
import privacyPolicy from "./views/privacyPolicy.vue";

const router = new VueRouter({
    routes: [
        { path: "/", component: home },
        { path: "/privacyPolicy", component: privacyPolicy }
    ],
});

new Vue({
    render: (h) => h(App),
    router,
}).$mount("#app");
