<template>
  <div class="content">
    <img class="bg" alt="Vue logo" src="~@/assets/bg.png">
    <div class="footer">
      <div>
        <img class="logo" alt="Vue logo" src="~@/assets/footer-logo.png">
        <p>上海上语通信科技有限公司</p>
        <a class="link" target="view_window" href="https://beian.miit.gov.cn/">苏ICP备2023021211号</a>
      </div>
      <div>
        <p class="title">技术服务热线</p>
        <p class="big">0515-67630246</p>
        <p>服务时间：9:00-18:00（工作日）</p>
      </div>
      <div>
        <p class="title">邮箱</p>
        <p class="big">huangpp@cangfengsz.com<span class="testVideo" @click="isTestShow = true"></span></p>
      </div>
    </div>
    <div v-show="isTestShow" class="textBox">
      <input class="testIpt" type="text" v-model="testVideoUrl" placeholder="url">
      <input class="testIpt" type="text" v-model="testType" placeholder="type">
      <input class="testIpt" type="text" v-model="testId" placeholder="id">
      <button class="testIpt" @click="testVideo">初始化video</button>
      <input class="testIpt" type="text" v-model="testFetch" placeholder="id">
      <input class="testIpt" type="text" v-model="fetchCors" placeholder="fetchCors">
      <button class="testIpt" @click="handleTestFetch">testFetch</button>
      <video v-if="isVideoInit" :id="testId" class="video-js video-box" controls preload="auto" data-setup='{}'>
        <source :src="testVideoUrl" :type="testType">
      </video>
    </div>
  </div>
</template>

<script>
// import videoPlus from "@/components/videoPlus/index";
import 'video.js/dist/video-js.css'
import videojs from "video.js";
import "videojs-contrib-hls";
export default {
  name: "homePage",
  // components: {
  //   videoPlus,
  // },
  data() {
    return {
      // testVideoUrl: 'http://devimages.apple.com/iphone/samples/bipbop/gear1/prog_index.m3u8'
      // testVideoUrl: 'https://www.w3school.com.cn/i/movie.mp4'
      testVideoUrl: "http://192.168.1.7:5075/tinymce/2024/9/19/1df9cc93-bb51-480e-9557-90067471994b.mp4",
      testType: 'video/mp4',
      testFetch: 'http://192.168.1.7:5075/api/Bed/GetList',
      fetchCors: 'no-cors',
      testId: 'my-video',
      isTestShow: false,
      isVideoInit: false,
      myPlayer: null,
      showFlag: true,
      count: 0
    };
  },
  mounted() {
    // this.testVideo()
  },
  methods: {
    testVideo() {
      
      this.isVideoInit = true;
      setTimeout(() => {
        this.myPlayer = videojs(this.testId, {
          bigPlayButton: false,
          textTrackDisplay: false,
          posterImage: true,
          errorDisplay: false,
          controlBar: true,
        });
        
      }, 2000);
    },
    handleTestFetch() {
      fetch(this.testFetch, {
        method: "POST",
        mode: this.fetchCors,
        headers: {
          "Accept": "*/*",
          "Content-Type": "application/json;charset=utf-8"
        },
        body: JSON.stringify({}),
      }).then(res => {
        res.json()
      }).then(data => {
        console.log(data)
      }).catch(error => {
        console.log(error.msg)
      })
    }
  },
};
</script>

<style scoped>
body,
html,
p {
  margin: 0;
  padding: 0;
}
.testVideo {
  display: inline-block;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.content {
  position: relative;
}
.bg {
  width: 100%;
  display: block;
}
.link {
  color: #f0f0f0;
  font-size: 12px;
}
.footer {
  height: 12%;
  width: 78%;
  color: #f0f0f0;
  position: absolute;
  bottom: 0;
  left: 11%;
  z-index: 2;
  font-size: 12px;
  display: flex;
  align-items: center;
  line-height: 40px;
}
.footer > div {
  width: 33.3%;
  height: 100px;
}
.footer .logo {
  height: 23px;
}
.title {
  font-size: 18px;
  /* line-height: 22px; */
}
.big {
  font-size: 22px;
  /* line-height: 22px; */
}
.videobox {
  width: 500px;
  height: 500px;
  overflow: hidden;
}
.textBox{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.4);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
.testIpt{
  width: 300px;
  height: 30px;
  display: block;
}
</style>
