<template>
  <div class="content">
七建微项目隐私权保护政策
<br>
APP名称：七建微项目
<br>
发布公司：上海上语通信科技有限公司
<br>
发布日期：2022年3月22日
<br>
欢迎您使用 为保障您的相关权利，《七建项目管理微平台隐私权保护政策》（下文称“本隐私政策”）将向您说明七建项目管理微平台会如何收集、使用和存储您的个人信息及您享有何种权利，请您在使用七建项目管理微平台APP之前，阅读、了解并同意本政策，您在正式使用账户时即视为您接受本协议及各类规则，并同意受其约束。本政策适用于七建项目管理微平台产品自身的功能及服务（包括人员管理、通讯录、人脸识别等的办公应用）。本隐私政策将帮助您了解以下内容：
<br>
1.我们收集哪些类型的信息
<br>
2.我们如何存储这些信息
<br>
3.我们如何保护这些信息
<br>
4.我们如何使用这些信息
<br>
5.信息的分享和对外提供
<br>
6.本隐私指引的变更
<br>
7.其它
<br>
8.联系我们
<br>
相关定义：
<br>
七建项目管理微平台：七建项目管理微平台是由上海上语通信科技有限公司提供的一款工地信息的大数据平台工具。七建项目管理微平台产品及服务，包括七建项目管理微平台服务端及七建项目管理微平台客户端（包括iOS、Android等多个应用版本）等。
<br>
七建项目管理微平台服务端： 指为七建项目管理微平台产品提供信息采集、传输、存储服务的软件系统。
<br>
企业IT管理员： 指经企业指定，拥有七建项目管理微平台系统后台操作权限的个人；企业IT管理员可以一人或多人。
<br>
个人：指被企业确认并认证的人员。
<br>
个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
<br>
个人敏感信息：指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，包括身份证件号码、个人生物识别信息、通信记录和内容、财产信息、行踪轨迹、健康生理信息、交易信息等。
<br>
1.我们收集哪些类型的信息
<br>
为了向您提供服务、保障服务的正常运行、改进和优化我们的服务以及保障账号安全，七建项目管理微平台会按照如下方式收集您在使用服务时主动提供、授权提供或因为使用服务而产生的信息：
<br>
1.1 账号信息：您首次登录七建项目管理微平台，使用的账号及密码信息是由企业IT管理员在后台为您预分配的，您登录系统之后可以通过七建项目管理微平台提供的账号密码及个人信息修改功能修改您的账号密码、头像、手机号码、邮箱等个人信息，这些信息将上报到七建项目管理微平台服务端上进行存储，这些信息的归属于上海建工，并由企业IT管理员所管理。
<br>
1.2 当您使用七建项目管理微平台时，为向您及企业提供七建项目管理微平台产品及服务，维护我们服务的正常运行，改进及优化我们的服务体验以及保障您的账号安全，系统会收集您下述信息：
<br>
1.2.1 设备信息：根据您在安装及使用七建项目管理微平台过程中的设备型号及授予的权限，我们会收集使用七建项目管理微平台的设备相关信息，包括设备型号、操作系统、唯一设备标识符、设备所在位置信息（如登录IP地址、GPS位置以及能够提供相关信息的Wi-Fi接入点等相关信息）、七建项目管理微平台软件版本号以及设备加速器（如重力感应设备）等。
<br>
1.2.2 日志信息：当您使用七建项目管理微平台时，系统会收集您对服务使用的日志信息，包括接入网络的方式、类型和状态、网络质量数据、操作日志、服务日志信息。
<br>
您理解并同意，以上信息中均可能被保存并记录在七建项目管理微平台服务端，其中的设备型号、操作系统、使用的软件版本、当前所访问的企业ID、当前所使用的网络状态等信息将会被传递到上海上语通信科技有限公司APP版本升级服务做为升级检测的判断数据。
<br>
1.3 为提供工地管理及服务，系统会收集用户使用七建项目管理微平台过程中提交或产生的信息和数据（下称“企业控制数据”），企业控制数据可能包括：
<br>
1.3.1 您的姓名、照片、性别、手机号码等企业要求您提供的个人信息；
<br>
1.3.2 您的项目的地理位置信息及施工过程中的图片视频等信息；
<br>
1.3.3 其他由企业提交的数据，如施工现场视频监控等。
<br>
您理解并同意，这些数据均存储在七建项目管理微平台服务端上，企业为企业控制数据的控制者，系统仅根据企业的指示（包括企业以及企业管理员通过管理后台进行的操作等）以及我们与企业间的协议进行相应的处理。如果您对以上企业控制数据的收集目的、范围和使用方式有任何疑问或意见，请联系企业管理员处理。
<br>
1.4 当企业需要启用七建项目管理微平台的人脸识别考勤时，需要将您的个人照片信息上传到第三方人脸识别服务平台作为人脸识别功能的基础数据。在企业要求您上传并由企业管理您的照片信息之前，请确保企业已经事先告知并获得您的明确同意，仅收集实现运营及功能目的所必需的您的信息，且已充分告知您相关数据收集的目的、范围及使用方式等。
<br>
1.5 当您使用七建项目管理微平台的功能中部分功能（如人员管理、视频监控管理等）的时候，企业为了安全性考虑会启用人脸识别对您进行身份核验，这个过程可能会收集您主动提供的人脸图像信息。上述信息属于敏感信息，拒绝提供该信息仅会使您无法使用对应的功能。
<br>
1.6 当企业使用安全管理、施工机械设备管理功能时，需要通过收集项目管理有关数据、移动定位数据、视频监控数据等对工地与现场设备进行安全管控和预警，此过程可能会收集工地现场机械设备等的具体数据信息，该数据仅用于此目的，拒绝提供该信息会导致您物法使用对应功能。
<br>
1.7 视频展示和播放
<br>
1.7.1 我们的产品/服务为您提供视频展示和播放服务，在此过程中，我们需要收集（包括在后台状态下收集）您的如下个人信息：设备型号、设备名称、设备标识信息、浏览器类型和设置、语言设置、操作系统和应用程序版本、登录IP地址、接入网络的方式、网络质量数据、移动网络信息、产品版本号、网络日志信息（操作日志、服务日志）。我们收集这些信息是为了向您提供视频展示和播放服务，如您拒绝提供上述权限将可能导致您无法使用我们的相关产品与服务。请您理解，单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。
<br>
1.7.2 请您理解，您只有提供上述信息才能使用我们的核心功能，如果您选择不提供上述为实现产品功能的必备信息，或将导致我们无法为您提供该核心产品功能。
<br>
2.我们如何存储这些信息
<br>
2.1 信息存储的地点除了上文特别描述的第三方的信息外，其他信息均存储在企业自己的服务器上，如需要了解企业服务器所处的具体地理位置，请咨询企业管理员。
<br>
2.2 信息存储的期限一般而言，我们仅为实现目的所必需的时间保留您的个人信息。对于企业可自主设定留存期限的部分企业控制数据（如考勤记录、图片视频等），系统会根据企业的设置留存相关信息。具体的存储规则可以咨询企业管理员。
<br>
3.我们如何保护这些信息
<br>
3.1 我们会在系统层面努力为用户的信息安全提供保障，以防止信息的丢失、不当使用、未经授权访问或披露。
<br>
3.2 我们将在合理的安全水平内建议企业使用各种安全保护措施以保障信息的安全。例如，我们会使用加密技术（例如，SSL /TLS）、匿名化处理等手段来保护您的个人信息。
<br>
3.3 因为七建项目管理微平台系统是部署在企业自己的服务器及网络环境内的系统，所以七建项目管理微平台系统部署及运行基础环境的安全性由企业提供保障，我们会给与企业合理的基础运行环境的安全建议。不同的企业会根据自己的安全需求可能会采用不同的安全策略，对于企业基础运行环境的保障措施可以咨询企业管理员。
<br>
4.我们如何使用信息
<br>
4.1 对于使用七建项目管理微平台过程中授权系统获取的个人信息、设备信息主要用于保障系统功能的正常运行，不会用于任何其他商业用途。
<br>
4.2 对于企业控制数据的使用，请咨询对应的企业管理员。
<br>
5.信息的分享及对外提供
<br>
除了上文描述中为了保障系统功能的使用，七建项目管理微平台需要和第三方系统进行交互是会传递必要的数据给第三方系统外，系统本身不会自动共享或转让您的个人信息至第三方。 企业是否需要对您提交的数据进行分享和对外提供，请咨询对应的企业管理员。
<br>
6.本隐私指引的变更
<br>
我们可能会适时对本隐私权政策进行修订。当政策条款发生变更时，我们会通过官网公告（https://www.syzhgd.com）向您告知。
<br>
7.其他
<br>
7.1 因为七建项目管理微平台产品部署在本企业的服务器上，并由企业的管理人员自行管理和维护，企业最终会如何使用这些功能收集信息、存储信息、使用信息需要咨询企业的管理人员。本协议只能针对产品中包含的隐私信息收集能力及一般性的收集使用的范围及存储方式进行说明。
<br>
7.2 应用实际运营者“上海上语通信科技有限公司”进行应用上架、维护和推广。上海上语通信科技有限公司负责企业信息化建设相关工作，“七建项目管理微平台”APP相关上架和维护工作获得上海上语通信科技有限公司认可。
<br>
8.联系我们
<br>
当您有其他的投诉、建议相关问题时，请通过 https://www.syzhgd.com 与我们联系。
<!-- 您也可以将您的问题发送至aics@scg.cn  -->
我们将尽快审核所涉问题，并在验证您的用户身份后的十五天内予以回复。

  </div>
</template>

<script>

export default {
  name: 'privacyPolicy'
}
</script>

<style scoped>
body,html{
  margin: 0;
  padding: 0;
}
.content {
  position: relative;
  padding: 10px;
  line-height: 22px;
  font-size: 14px;
}
</style>
